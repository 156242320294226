//jshint  esversion: 6
//import './normalize.css';
import './bootstrap.min.css';
//import './apps.scss';
//import './light-border.scss';
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import './styles.scss';

import 'bootstrap/js/dist/dropdown';
import tippy from 'tippy.js';

import { select, selectAll, Selection } from "d3-selection";
import { geoEqualEarth, geoPath } from "d3-geo";
//import { zoom } from "d3-zoom";
import { json, csv, xml } from "d3-fetch";
import { min, max } from "d3-array";
import { scalePow } from "d3-scale";
import { transition, Transition } from "d3-transition";
import { interpolateString } from "d3-interpolate";
import { format } from "d3-format";
import { forceSimulation, forceCollide, forceManyBody, forceX, forceY, forceCenter } from "d3-force";

//import { library, dom } from "@fortawesome/fontawesome-svg-core";
//import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

//library.add(faCheck);
//dom.watch();

//import '@fortawesome/fontawesome-free/js/fontawesome';
//import '@fortawesome/fontawesome-free/js/solid';
//import '@fortawesome/fontawesome-free/js/regular';
//import '@fortawesome/fontawesome-free/js/brands';


// initial setup

var MODE = "explore";
var READY_DATA = false;
var READY_MAP = false;

// hide overlay
selectAll('.cross')
    .on('click', function(){
        selectAll('.overlay').style('display','none');
        event.preventDefault();
    });

// draw for resize

function redraw(){
 
    // Extract the width and height that was computed by CSS.
    mapWidth = svg.clientWidth;
    mapHeight = svg.clientHeight;

    // Use the extracted size to set the size of an SVG element.
    svg
      .attr("width", mapWidth)
      .attr("height", mapHeight);

}

// WRITE PAGE 

// TITLE
/*
const header = select("body")
    .append("div")
    .attr("id", "header");

header.append("h1")
    .text("Climate Policy Barriers")
    .attr("class", "title");

header.append("h1")
    .text("A world atlas of challenges and barriers for implementation of climate policies")
    .attr("class", "subtitle");
*/
//const container = select('body')
const container = select('#maincontent')
    .append('div')
    .attr('id','main-container');

const nav = container
    .append("nav")
    .classed("navbar-expand",true)
    .attr("id", "header")
    .classed('navbar','true')
    .classed("navbar-expand",true)
    .classed('justify-content-md-center',true)
    .classed("navbar-dark",true);

nav.append('a')
    .classed('navbar-brand',true)
    .attr('href','/')
    .text('Atlas of Climate Policy Barriers');

const menu = nav.append('div')
    .classed('collapse',true)
    .classed('navbar-collapse',true)
    .attr('id','menu')
    .append('ul')
    .classed('navbar-nav',true)
    .classed('flex-row',true)
    .classed('ml-md-auto',true)
    .classed('d-none',true)
    .classed('d-md-flex',true)
    ;

menu.append('li')
    .classed('nav-item',true)
    .classed('active',true)
    .attr('id','explore')
    .append('a')
    .classed('nav-link',true)
    .attr('href','#')
    .attr('id','lnk-explore')
    .on("click", changeMode)
    .text('Explore');

menu.append('li')
    .classed('nav-item',true)
    .classed('active',false)
    .attr('id','combine')
    .append('a')
    .classed('nav-link',true)
    .attr('id','lnk-combine')
    .attr('href','#')
    .on("click", changeMode)
    .text('Combine');

const menushare = menu.append('li')
    .classed('nav-item',true)
    .classed('dropdown',true);

menushare.append('a')
    .classed('nav-link',true)
    .classed('dropdown-toggle',true)
    .attr('id','dropdownMenuLink')
    .attr('href','#')
    .attr('role','button')
    .attr('data-toggle','dropdown')
    .attr('aria-haspopup','true')
    .attr('aria-expanded','false')
    .text('Share');

const menusharedwn = menushare.append('div')
    .classed("dropdown-menu","true")
    .classed("dropdown-menu-right","true")
    .attr('aria-labelledby','dropdownMenuLink');

menusharedwn.append('a')
    .classed('dropdown-item','true')
    .attr('href','https://twitter.com/home?status=climate-policy-barriers.netlify.com')
    .attr('target','_blank')
    .text('twitter');

const mf = menusharedwn.append('a')
    .classed('dropdown-item','true')
    .attr('href','https://www.facebook.com/sharer/sharer.php?u=climate-policy-barriers.netlify.com')
    .attr('target','_blank')
    .text('facebook');

menusharedwn.append('a')
    .classed('dropdown-item','true')
    .attr('href','https://pinterest.com/pin/create/button/?url=climate-policy-barriers.netlify.com/&media=&description=')
    .attr('target','_blank')
    .text('pinterest');

menusharedwn.append('a')
    .classed('dropdown-item','true')
    .attr('href','https://www.linkedin.com/shareArticle?mini=true&url=climate-policy-barriers.netlify.com/&title=&summary=&source=')
    .attr('target','_blank')
    .text('linkedin');

menusharedwn.append('a')
    .classed('dropdown-item','true')
    .attr('href','mailto:info@example.com?&subject=&body=climate-policy-barriers.netlify.com/ ')
    .attr('target','_blank')
    .text('email');

// MAP
const map = container
    .append("div")
    .attr("id", "map");

// FOOTER
//const footer = container
//    .append("div")
//    .attr("id", "footer");

const footer = select('#footer');

footer.append("img")
    .classed("eiee_logo", true)
    .attr("src", "img/EIEE_2019_Logo.svg");

footer.append("img")
    .classed("flag", true)
    .attr("src", "img/Flag_of_Europe.svg");

footer.append("img")
    .classed("cdlinks_logo", true)
    .attr("src", "img/CD-LINKS_logo_white.png");

footer.append("p")
    .text("This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 642147 (CD-LINKS).");


var mapWidth = Math.min(document.documentElement.scrollWidth,1400);
//var height = width / 2.05458; // ratio of equal earth projection 
var mapHeight = mapWidth / 2.05458; // ratio of equal earth projection wo ATA
var pageHeight = document.documentElement.scrollHeight;
var headerHeight = document.getElementById('header').scrollHeight;
var footerHeight = document.getElementById('footer').scrollHeight;
//console.log(mapHeight);
//console.log(mapWidth);
//console.log(pageHeight);
//console.log(headerHeight);
//console.log(footerHeight);

//console.log(mapHeight + footerHeight + headerHeight );


//if (mapHeight + footerHeight + headerHeight > pageHeight) {
//    mapHeight = (pageHeight - footerHeight - headerHeight) * 0.8;
//    mapWidth = mapHeight * 2.05458;
//}

//console.log(mapHeight);
//console.log(mapWidth);
var posx = mapWidth / 2;
var posy = mapHeight / 1.85;
var scale = mapHeight / 2.5;

select('#header')
    .style('width', mapWidth + 'px');

var mapcontainer = map
    .append('div')
    .attr('id','mapcontainer')
    .style('width', mapWidth + 'px');

var svg = mapcontainer
    .append('svg')
    .attr("id","svgmap")
    .attr('width', mapWidth)
    .attr('height', mapHeight);

//toolbar
/*var toolbar = mapcontainer.append("div")
    .attr('id','toolbar');

var input = toolbar.append("label")

input.append('input')
.attr('type','checkbox')
.attr('id','mode')
.property('checked',false)
.on("change", changeMode);

input
.append('span')
.attr('class','label_indicator')
.text('Create a composite indicator with multiple selection.');
*/

// Create an inner SVG panel
var inner_svg = svg.append("g")
       .attr("transform", "translate(" + 0 + "," + 0 + ")");

// Goeey effect [http://bl.ocks.org/nbremer/8df57868090f11e59175804e2062b2aa]
var defs = svg.append("defs");
var filter = defs.append("filter").attr("id","gooeyCodeFilter");
filter.append("feGaussianBlur")
    .attr("in","SourceGraphic")
    .attr("stdDeviation","10")
    .attr("color-interpolation-filters","sRGB") 
    .attr("result","blur");
filter.append("feColorMatrix")
    .attr("class", "blurValues")
    .attr("in","blur")
    .attr("mode","matrix")
    .attr("values","1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -5")
    .attr("result","gooey");
filter.append("feBlend")
    .attr("in","SourceGraphic")
    .attr("in2","gooey")
    .attr("operator","atop");

var filter2 = defs.append("filter").attr("id","negativeFilter");
filter2.append("feColorMatrix")
.attr("in","SourceGraphic")
.attr("type","matrix")
.attr("values","-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0");

inner_svg.append("g")
    .attr("class", "worldmapcontainer");

var mapProjection = geoEqualEarth()
    .scale(scale)
    .translate([posx, posy]);

var mapPath = geoPath().
    projection(mapProjection);

var centroid = new Map();

var indicators = new Set();

var promises = [
    json("maps/ne_110m_admin_0_countries_wo_ata_simp50.json"),
    json("maps/geo_r5.geojson")
];

Promise.all(promises).then(ready);

function draw_map(topo,res){
    inner_svg.selectAll(".worldmapcontainer")
        .append("g")
        .classed("worldmap", true)
        .classed(res, true)
        .selectAll("path")
        .data(topo.features)
        .enter()
        .append("path")
        .attr('class',function(d,i) {
            if (res=="iso3") {
                return(d.properties.ADM0_A3);
            } else {
                return(d.properties.REG_NAME);
            }
        })
        .attr('fill', function(d,i) {
            if (res=="r5") {
                return (centroid.get(d.properties.REG_NAME).color);
            } else {
                return ("#ddd");
            }
        })
        .attr("d", mapPath);
}

function show_map(res) {

    selectAll(".worldmap")
        .style("opacity", 0);
    selectAll(".worldmap." + res)
        .style("opacity", 1);

}

function load_centroid(data) {
    data.forEach(function(d) {
        centroid.set(d.iso3, {lat: d.Latitude, lon: d.Longitude, name: d.name});
    });
    // centroid for R5 regions
    centroid.set("1", {lat: 15, lon: 105, name: "Asia", color: "#eee"});
    centroid.set("2", {lat: -10.54, lon: -65.64, name: "Latin America", color: "#ddd"});
    centroid.set("3", {lat: 10, lon: 20, name: "Africa/Middle East", color: "#ccc"});
    centroid.set("4", {lat: 40, lon: -80, name: "OECD", color: "#aaa"});
    centroid.set("5", {lat: 60, lon: 90, name: "Former Soviet Union", color: "#ddd"});
    centroid.set("R5ASIA", centroid.get("1"));
    centroid.set("R5LAM", centroid.get("2"));
    centroid.set("R5MAF", centroid.get("3"));
    centroid.set("R5OECD", centroid.get("4"));
    centroid.set("R5REF", centroid.get("5"));
}

function ready(topo) {

    draw_map(topo[0],"iso3");
    draw_map(topo[1],"r5");

    show_map("iso3"); 

    console.log("Ready Map!");

    READY_MAP = true;

}

// DATA

var dat = {iso3: {}, r5: {}};

var nodes = [];

var promises_data = [
    csv("data/centroid_countries.csv"),
    json("data/data_iso3_planned_coal_plant.json"),
    json("data/data_iso3_fossil_fuel_subsidies.json"),
    json("data/data_iso3_cons_based_co2.json"),
    json("data/data_iso3_economic_freedom.json"),
    json("data/data_iso3_education_girl.json"),
    json("data/data_r5_low_carbon_inv.json")
];

Promise.all(promises_data).then(ready_data);

function ready_data(data) {

    load_centroid(data[0]);

    loadData(data[1],"coal","iso3");
    loadData(data[2],"ffuel","iso3");
    loadData(data[3],"cco2","iso3");
    loadData(data[4],"econ","iso3");
    loadData(data[5],"educ","iso3");
    loadData(data[6],"lcinv","r5");

    init_scales();

    init_sinks();

    init_simulation();

    console.log("Ready Data!");

    READY_DATA = true;

}

function loadData(data,idc,reg){
    var newnodes = data.map(function (d) {
        return {
            idc: idc,
            res: reg,
            name: d[reg],
            value: d.value,
            lon: +centroid.get("" + d[reg]).lon,
            lat: +centroid.get("" + d[reg]).lat    
        };
    });
    newnodes.sort(function (a, b) {
        return b.value - a.value;
    });
    nodes = nodes.concat(newnodes);
}

//Wrapper for the bubbles
var bubbleWrapper = svg.append("g")
    .attr("class", "bubbleWrapper")
    .style("filter", "url(#gooeyCodeFilter)");

var sink = require('./sink.json');

// Sink position [TO BE IMPROVED]
sink.coal.x = 0;
sink.ffuel.x = 0;
sink.lcinv.x = 0;
sink.cco2.x = mapWidth;
sink.econ.x = mapWidth;
sink.educ.x = mapWidth;

sink.coal.y = mapHeight / 6 * 1;
sink.ffuel.y = mapHeight / 6 * 2;
sink.lcinv.y = mapHeight / 6 * 3;
sink.cco2.y = mapHeight / 6 * 1;
sink.econ.y = mapHeight / 6 * 2;
sink.educ.y = mapHeight / 6 * 3;

sink.coal.r = mapHeight / 6 / 2;
sink.ffuel.r = mapHeight / 6 / 2;
sink.lcinv.r = mapHeight / 6 / 2;
sink.cco2.r = mapHeight / 6 / 2;
sink.econ.r = mapHeight / 6 / 2;
sink.educ.r = mapHeight / 6 / 2;

const sinks = Object.keys(sink);

var scales = {};

var bubbles = null;
var bubblesT = null;

function init_scales() {

    sinks.forEach(idc => {
        var mn = min(nodes.filter(function(d){if(d.idc===idc){return d;}}), function(d) { return d.value; });
        var mx = max(nodes.filter(function(d){if(d.idc===idc){return d;}}), function(d) { return d.value; });
    
        scales[idc] = scalePow()
            .exponent(0.66)
            .range([mapHeight / 96, mapHeight / 12])
            .domain([mn,mx]);  
    });

    nodes.forEach(e => {
        e.r_scaled = scales[e.idc](e.value);
    });

}


function init_sinks() {

    // Bubbles
    bubbles = bubbleWrapper.selectAll('.bubble')
                    .data(nodes, function (d) {
                        return "id" + d.id;
                    });

    var bubblesI = bubbles
        .enter()
        .append('circle')
        .attr("class", function(d) { return d.idc; })
        .classed('bubble', true)
        .attr("r", function(d) { return sink[d.idc].r0; })
        .attr("cx", function(d) { return sink[d.idc].x; })
        .attr("cy", function(d) { return sink[d.idc].y; })
        .each(function(d){
            tippy(this,{
                "theme": 'light-border',
                "content": centroid.get("" + d.name).name + 
                ( d.res == "iso3" ? ' (' + d.name + ')' : '') + 
                '<br/>' + format(",.2r")(d.value) + ' ' +  sink[d.idc].units
            });
        });
    
    bubbles = bubbles.merge(bubblesI);

    // Sinks
    sinks.forEach(idc => {
        bubbleWrapper.append("circle")
            .attr("class", idc + "Sink")
            .attr("r", sink[idc].r)
            .attr("cx", sink[idc].x)
            .attr("cy", sink[idc].y);

        bubbleWrapper.append("circle")
            .attr("id", "zone_" + idc)
            .attr("class", "zone_" + idc)
            .attr("r", sink[idc].r - 4)
            .attr("cx", sink[idc].x)
            .attr("cy", sink[idc].y)
            .style("opacity", 0)
            .on("mouseover", handleMouseOverIdc)
            .on("mouseout", handleMouseOutIdc)
            .on("click", handleClickIdc);
        
        svg.append('image')
            .attr('xlink:href', './img/' + idc + '.png')
            .attr("id", "zone_" + idc)
            .classed("icon", true)
            .classed("zone_" + idc, "true")
            .attr('x', sink[idc].x - (sink[idc].x>0 ? sink[idc].r * 0.66 : 0) + sink[idc].icodx * sink[idc].r)
            .attr('y', sink[idc].y - sink[idc].r * 0.66 / 2 + sink[idc].icody * sink[idc].r)
            .attr('width', sink[idc].r * 0.66 * sink[idc].icos )
            .attr('height', sink[idc].r * 0.66 * sink[idc].icos )
            .on("mouseover", handleMouseOverIdc)
            .on("mouseout", handleMouseOutIdc)
            .on("click", handleClickIdc);

        sink[idc].tip = tippy(document.querySelector('.icon.' + "zone_" + idc), {
            "arrow": true,
            "content": sink[idc].definition,
            "maxWidth": 200,
            "delay": [0,200],
            "hideOnClick": false
        });
    });

}

function init_simulation() {

    simulation
        .nodes(nodes)
        .force("x", forceX(function (d) {return sink[d.idc].x;}))
        .force("y", forceY(function (d) {return sink[d.idc].y;}));
        
    simulation.stop();

}

// EXPLORE MODE

function add_gooey_filter() {

    selectAll(".blurValues")
        .transition().duration(500)
        .attrTween("values", function () {
            return interpolateString("1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 6 -5",
                "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -5");
        });

}

function remove_gooey_filter() {

    selectAll(".blurValues")
        .transition().duration(1500)
        .attrTween("values", function () {
            return interpolateString("1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -5",
                "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 6 -5");
        });

}


function go_sink(idc) {

    //Sink increases
    selectAll("." + idc + "Sink")
        .transition().duration(1000)
        .attr("r", sink[idc].r);

    //Put the circle in the sink
    selectAll("." + idc)
        .transition("move").duration(1000)
        .delay(function(d,i) { return i*20; })
        .attr("r", sink[idc].r0)
        .attr("cx", sink[idc].x)
        .attr("cy", sink[idc].y)
        .style("opacity", 0.8);

    add_gooey_filter();

}

function go_map(idc, remove_effect = true) {

    show_map(sink[idc].res);

    //Sink decreases
    selectAll("." + idc + "Sink")
        .transition().duration(1000)
        .attr("r", sink[idc].r * 0);

    //Put the circle in their geolocation
    selectAll("." + idc)
        .transition("move").duration(1000)
        .delay(function(d,i) { return i*20; })
        .attr("r", function(d) {
            d.radius = scales[idc](d.value);
            return d.radius;
        })
        .attr("cx", function(d) {
             d.x = mapProjection([d.lon, d.lat])[0];
            return d.x;
        })
        .attr("cy", function(d) {
            d.y = mapProjection([d.lon, d.lat])[1];
            return d.y;
        })
        .style("opacity", 0.8);
        
    if (remove_effect) {

        //Around the end of the transition above make the circles see-through a bit
        selectAll("." + idc)
            .transition("dim").duration(1000).delay(1000)
            .style("opacity", 0.8);

        remove_gooey_filter();

    }

    legend_idc(idc);

}

function go_sink_force(idc) {

    //Sink increases
    selectAll("." + idc + "Sink")
        .transition().duration(1000)
        .attr("r", sink[idc].r);

}

function go_map_force(idc, remove_effect = true) {

    show_map(sink[idc].res);

    //Sink decreases
    selectAll("." + idc + "Sink")
        .transition().duration(1000)
        .attr("r", sink[idc].r * 0);
 
}

function go_force() {

    selectAll(".bubble")
        .transition("move").duration(1000)
        .attr("r", function(d) {
            if(indicators.has(d.idc)){
                return d.r_scaled / Math.pow(indicators.size,0.8) * 0.9;
            } else {
                return 0;
            }
        })
        .style("opacity", 0.8);

    simulation.stop();
    
    simulation = forceSimulation()
        .nodes(nodes)
        .velocityDecay(0.25)
        .on("tick", ticked)
        .force('collision', forceCollide().radius(function(d) {
            if(indicators.has(d.idc)){
                return d.r_scaled / Math.pow(indicators.size,0.8) * 0.9;
            } else {
                return 0;
            }
          }))
        .force("x", forceX(function (d) {
            if(indicators.has(d.idc)){
                return mapProjection([d.lon, d.lat])[0];
            } else {
                return sink[d.idc].x;
            }
        }).strength(0.025))
        .force("y", forceY(function (d) {
            if(indicators.has(d.idc)){
                return mapProjection([d.lon, d.lat])[1];
            } else {
                return sink[d.idc].y;
            }
        }).strength(0.025));
        
    simulation.restart();   

    //add_gooey_filter();

}


function legend_idc(idc){

    selectAll(".legend").remove();

    for (let i = 0; i < sink[idc].breaks.length; i++) {
        const e = sink[idc].breaks[i];

        svg.append("circle")
        .classed("legend", true)
        .classed("key", true)
        .attr("r", scales[idc](e))
        .attr("cx", mapWidth * 0.1)
        .attr("cy", mapHeight - 36 - scales[idc](e) );

        svg.append("line")
        .classed("legend", true)
        .classed("line", true)
        .attr("stroke-dasharray", "4, 1")
        .attr("x1", mapWidth * 0.1)
        .attr("x2", mapWidth * 0.15 )
        .attr("y1", mapHeight - 36 - 2 * scales[idc](e) )
        .attr("y2", mapHeight - 36 - 2 * scales[idc](e) );

        svg.append("text")
        .classed("legend", true)
        .classed("label", true)
        .attr("x", mapWidth * 0.15 + 2 )
        .attr("y", mapHeight - 36 - 2 * scales[idc](e) )
        .attr("dominant-baseline","middle")
        .text(sink[idc].breaks_lbl[i]);

    }

    svg.append("text")
    .classed("legend", true)
    .classed("title", true)
    .attr("x", mapWidth * 0.15 )
    .attr("y", mapHeight - 54 - 2 * scales[idc](sink[idc].breaks[sink[idc].breaks.length - 1]) )
    .attr("dominant-baseline","middle")
    .attr("text-anchor","middle")
    .text(sink[idc].legend_title);

    svg.append("text")
    .classed("legend", true)
    .classed("source", true)
    .attr("x", mapWidth * 0.05 )
    .attr("y", mapHeight - 18 )
    .attr("dominant-baseline","middle")
    .text('Source: ')
    .append("a")
    .attr("href", sink[idc].source_url)
    .attr("target","_blank")
    .text(sink[idc].source);

}



function handleMouseOverIdc(d, i) {
    if ( MODE == "explore" & READY_DATA & READY_MAP) {
        var idc = select(this).attr("id").substring(5);
        go_map(idc);
        
        //highlight icon
        svg.selectAll(".icon.zone_" + idc)
            .style("filter", "url(#negativeFilter)");
    }
}

function handleMouseOutIdc(d, i) {
    if ( MODE == "explore" & READY_DATA & READY_MAP) {
        selectAll(".legend").remove();    
        var idc = select(this).attr("id").substring(5);
        go_sink(idc);

        //unhighlight icon
        svg.selectAll(".icon.zone_" + idc)
            .style("filter", "");

    }
}

function handleClickIdc(d, i) {
    if ( READY_DATA & READY_MAP) {
        var idc = select(this).attr("id").substring(5);
        if ( MODE == "explore" | MODE == "select" ){
            indicators.forEach( i => {
                sink[i].tip.enable();    
                sink[i].tip.hide();
            });
            if ( indicators.has(idc) ) {
                indicators.delete(idc);
                go_sink(idc);
            } else {
                indicators.forEach( i => {
                    indicators.delete(i);
                    go_sink(i);
                });
                indicators.add(idc);
                go_map(idc, MODE == "select");
            }
            if (indicators.size == 0) {
                MODE = "explore";
            } else {
                MODE = "select";
                sink[idc].tip.show();
                sink[idc].tip.disable();
            }
        }
        if ( MODE == "indicator" ){
            sink[idc].tip.enable();
            if ( indicators.has(idc) ) {
                indicators.delete(idc);
                go_sink_force(idc);
                sink[idc].tip.hide();
            } else {
                indicators.add(idc);
                go_map_force(idc);
                sink[idc].tip.show();
            }
            go_force();
            sink[idc].tip.disable();
        }
        svg.selectAll(".icon")
            .style("filter", null);
        indicators.forEach( i => {
            svg.selectAll("#zone_" + i)
               .style("filter", "url(#negativeFilter)");
        });
    }

}

// changeMode
function changeMode(d) {
    console.log(this.id);
    var link = null;
    if(this.id === "lnk-explore") {
        link = select('#explore');
    } else {
        link = select('#combine');
    }
    if(!link.classed('active')){
        select('#explore').classed('active',this.id === "lnk-explore");
        select('#combine').classed('active',this.id === "lnk-combine");
        if(this.id === "lnk-explore"){
            MODE = "explore";
            indicators.forEach( i => {
                go_sink(i);
                sink[i].tip.enable(); 
                sink[i].tip.hide();
            });
            svg.selectAll(".icon")
                .style("filter", null);
            indicators.clear();
        } else {
            MODE = "indicator";
            selectAll(".legend").remove();
            indicators.forEach( i => {
                go_map(i, true);
            });    
        }
    }
    /*if(select("#mode").property("checked")){
        MODE = "indicator";
        selectAll(".legend").remove();
        indicators.forEach( i => {
            go_map(i, true);
        });
    } else {
        MODE = "explore";
        indicators.forEach( i => {
            go_sink(i);
            sink[i].tip.enable(); 
            sink[i].tip.hide();
        });
        svg.selectAll(".icon")
            .style("filter", null);
        indicators.clear();
    }*/
    event.preventDefault();	
}

var simulation = forceSimulation();

simulation.stop();

function ticked() {
    bubbles
        .attr('cx', function (d) {
            return d.x;
        })
        .attr('cy', function (d) {
            return d.y;
        });
}
